import React, { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './ConfirmModal.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

type Props = {
  show: boolean;
  onHide: () => void;
  handleSuccess: () => void;
  error?: string | null | unknown;
  centered?: boolean;
  title?: string;
  text?: string;
  cancelText?: string;
  yes?: string;
};

const ConfirmModal: FC<Props> = ({
  centered,
  show,
  onHide,
  handleSuccess,
  error,
  title = 'Gerät entfernen',
  text,
  cancelText = 'Abbrechen',
  yes = 'Entfernen',
}) => {
  return (
    <Modal centered={centered} show={show} onHide={onHide}>
      <Modal.Header closeButton className={styles.modalHeader} closeVariant="white" />
      <Modal.Body className={styles.modalBody}>
        <Row>
          <Col style={{ marginBottom: 20 }}>
            <h2>{title} </h2>
          </Col>
        </Row>
        {text && (
          <Row>
            <Col style={{ marginBottom: 10 }}>
              <h5>{text} </h5>
            </Col>
          </Row>
        )}
        <Row>
          <Col sm="6">
            <button onClick={onHide}>{cancelText}</button>
          </Col>
          <Col sm="6">
            <button onClick={handleSuccess}>{yes}</button>
          </Col>
          <Col sm="12">
            {error ? (
              <Alert show={!!error} variant={'danger'}>
                <>{error}</>
              </Alert>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmModal;
