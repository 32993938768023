import React, { FC, useEffect, useState } from 'react';
import styles from './EditPaymentMethod.module.scss';
import { PaymentMethodCard } from '../../components/PaymentMethodCard';
import { CustomSpinner } from '../../shared/Spinner';
import usePaymentTypesUsedQuery from '../../hooks/query/usePaymentTypesUsed';
import { PaymentUsed } from '../../hooks/models/PaymentUsedModel';
import useChangePrimaryPaymentMutation from '../../hooks/mutation/useChangePrimaryPaymentMutation';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ConfirmChangePrimaryModal from 'src/pages/EditPaymentMethod/ConfirmChangePrimaryModal/ConfirmChangePrimaryModal';
import infoIcon from '../../assets/img/infoIcon.svg';
import infoIconRed from '../../assets/img/infoIconRed.svg';
import { AddNewPaymentMethod } from './AddNewPaymentMethod';
import { PaymentModal } from '../../components/UI/Modals/PaymentModal';
import useAddNewSepaPaymentMutation from '../../hooks/mutation/payment/useAddNewSepaPaymentMutation';
import handleRocketGatePayment from '../blueMoviePlus/utils/handleRocketGatePayment';
import { usePaymentInitMutation } from '../blueMoviePlus/mutation';
import AddRocketGateModal from './AddRocketGateModal/AddRocketGateModal';
import { ConfirmModal } from '../../components/UI/Modals/ConfirmModal';
import useDeletePaymentMutation from '../../hooks/mutation/useDeletePaymentMutation';

interface EditPaymentMethodI {}

interface SepaPayment {
  iban: string;
  name: string;
  lastName: string;
  bank: string;
}

const EditPaymentMethod: FC<EditPaymentMethodI> = () => {
  const [sepaVisible, setSepaVisible] = useState(false);
  const [showRocketGate, setShowRocketGate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [pendingPayment, setPendingPayment] = useState<PaymentUsed | null>(null);

  const { paymentTypesUsed, isPaymentTypesUsedLoading } = usePaymentTypesUsedQuery();
  const { mutateChangePrimaryPayment } = useChangePrimaryPaymentMutation();
  const { mutateDeletePayment } = useDeletePaymentMutation();
  const { mutateAddNewSepaPayment, isAddNewSepaPaymentMutationLoading } = useAddNewSepaPaymentMutation();
  const { mutate: paymentInitMutate } = usePaymentInitMutation();

  const [selectedPayment, setSelectedPayment] = useState<PaymentUsed>({
    id: -1,
    info: '',
    gateway: '',
    image: '',
  });
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const handleSepaClose = () => {
    setSepaVisible(false);
  };
  const handleSepaShow = () => {
    setSepaVisible(true);
  };

  const handleShowConfirmModal = (payment: PaymentUsed) => {
    setPendingPayment(payment);
    if (selectedPayment.gateway !== 'QBOX') {
      setShowConfirmModal(true);
      return;
    }

    setShowModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
    setPendingPayment(null);
  };

  const handleConfirmPayment = () => {
    if (pendingPayment) {
      setSelectedPayment(pendingPayment);

      setTimeout(() => {
        // console.log(pendingPayment, 'pendingPayment');
        // mutateChangePrimaryPayment({ user_account_id: Number(pendingPayment.id) });

        handleSubmit(pendingPayment);
      }, 50);

      setShowConfirmModal(false);
      setPendingPayment(null);
    }
  };

  useEffect(() => {
    if (pendingPayment) {
      console.log(pendingPayment, 'pendingPayment');
    }
  }, [pendingPayment]);

  const handleDelete = (id: number) => {
    console.log(id, 'id');
    mutateDeletePayment(Number(id));
  };

  const handleCancelRocketGate = () => {
    setShowRocketGate(false);
  };

  const handleCardPayment = () => {
    handleRocketGatePayment(setIsLoading, paymentInitMutate, language, navigate);
  };

  const handleShowRocketGate = () => {
    setShowRocketGate(true);
  };

  useEffect(() => {
    if (
      paymentTypesUsed &&
      Array.isArray(paymentTypesUsed.payment_methods) &&
      paymentTypesUsed.payment_methods.length > 0
    ) {
      setSelectedPayment(paymentTypesUsed.payment_methods[0]);
    }
  }, [paymentTypesUsed]);

  const handleSubmit = (payment: PaymentUsed) => {
    if (paymentTypesUsed.payment_methods[0].gateway === 'QBOX') {
      setShowModal(true);
    } else {
      mutateChangePrimaryPayment({ user_account_id: Number(payment.id) });
    }
  };

  const handleConfirm = () => {
    if (pendingPayment) {
      mutateChangePrimaryPayment({ user_account_id: Number(pendingPayment.id) });
      setShowModal(false);
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handlePayment = (values: SepaPayment) => {
    mutateAddNewSepaPayment(values);
    setSepaVisible(false);
  };

  if (isPaymentTypesUsedLoading || isAddNewSepaPaymentMutationLoading || isLoading) {
    return <CustomSpinner />;
  }

  return (
    <div className={styles.container}>
      <header>
        <h2>{t('changePaymentMethod.title')}</h2>
      </header>
      <hr />
      <div className={styles.cards}>
        {paymentTypesUsed &&
          Array.isArray(paymentTypesUsed.payment_methods) &&
          paymentTypesUsed.payment_methods.map((payment: PaymentUsed, index) => (
            <PaymentMethodCard
              key={payment.id}
              selectedPayment={selectedPayment}
              setSelectedPayment={setSelectedPayment}
              payment={payment}
              handleDelete={handleDelete}
              handleShowConfirmModal={() => handleShowConfirmModal(payment)}
              handleCloseConfirmModal={handleCloseConfirmModal}
              index={index}
            />
          ))}
      </div>
      {selectedPayment.state === 'B' && (
        <div className={styles.inactive}>
          <p>
            Your primary payment method is inactive. If you don’t update your primary payment method, your subscription
            won’t be renewed.
          </p>
        </div>
      )}{' '}
      <div className={styles.info}>
        {paymentTypesUsed.message ? (
          <>
            <img src={infoIconRed} alt="" />
            <span>Die Zahlungsart können Sie nur bis zu drei Tage vor Ihrer nächsten Zahlung ändern.</span>
          </>
        ) : (
          <>
            <img src={infoIcon} alt="" />
            <span>
              Bitte beachten Sie, dass eine Änderung der Zahlungsart nur bis zu drei Tage vor der nächsten
              wiederkehrenden Zahlung möglich ist.
            </span>
          </>
        )}
      </div>
      <div className={styles.addNew}>
        <h2>{t('changePaymentMethod.addNewPayment')}</h2>
        <hr />
        <div className={styles.cards}>
          <AddNewPaymentMethod handleRocektGatePayment={handleShowRocketGate} isSepa={false} />
          <AddNewPaymentMethod handleSepaShow={handleSepaShow} isSepa={true} />
        </div>
      </div>
      <ConfirmModal
        title={t('confirmChangePrimaryModal.title')}
        text={t('confirmChangePrimaryModal.text')}
        cancelText={t('confirmChangePrimaryModal.cancel')}
        yes={t('confirmChangePrimaryModal.yes')}
        centered={true}
        show={showConfirmModal}
        onHide={handleCloseConfirmModal}
        handleSuccess={handleConfirmPayment}
      />
      <PaymentModal show={sepaVisible} handleClose={handleSepaClose} handlePayment={handlePayment} />
      <ConfirmChangePrimaryModal centered={true} show={showModal} onHide={handleCancel} onConfirm={handleConfirm} />
      <AddRocketGateModal
        centered={true}
        show={showRocketGate}
        onHide={handleCancelRocketGate}
        onConfirm={handleCardPayment}
      />
    </div>
  );
};

export default EditPaymentMethod;
